@import url("https://fonts.googleapis.com/css?family=Ovo|Karla");

// variables
$background-color: rgba(0, 0, 0, 0.95);
$body-font-color: #fcdcbf;
$body-font-family: Karla, sans-serif;
$body-font-size: 16px;
$border-color: #fcdcbf;
$container-background-color: #222222;
$darken-background-color: rgba(0, 0, 0, 0.05);
$h1-font-size: 48px;
$h2-font-size: 32px;
$header-font-color: #ffffff;
$header-font-family: Ovo, serif;
$spacing: 12px;
$media-phone-max-width: 320px;

// keyframes
@keyframes fade-in {
  0% {
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// dom styles
body {
  * {
    color: $body-font-color;
    font-family: $body-font-family;
    font-size: $body-font-size;
    outline: none;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    @media only screen and (max-width: $media-phone-max-width) {
      font-size: $body-font-size * 0.75;
    }
  }

  background: $background-color;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;

  a {
    cursor: pointer;
    font-weight: bold;
    pointer-events: auto;
    &:hover {
      color: lighten($body-font-color, 10%);
    }
  }

  p {
    margin-block-end: 3em;
    margin-block-start: 1em;
  }

  h1,
  h2 {
    color: $header-font-color;
    font-family: $header-font-family;
    margin: $spacing 0;
  }
  h1 {
    font-size: $h1-font-size;
    @media only screen and (max-width: $media-phone-max-width) {
      font-size: $h1-font-size * 0.75;
    }
  }
  h2 {
    font-size: $h2-font-size;
    @media only screen and (max-width: $media-phone-max-width) {
      font-size: $h2-font-size * 0.5;
    }
  }
}

.button {
  background-color: $container-background-color;
  border: 1px solid $border-color;
  border-radius: $spacing * 2;
  color: $body-font-color;
  cursor: pointer;
  padding: $spacing $spacing * 2;
  pointer-events: auto;
  &:hover {
    background-color: lighten($container-background-color, 10%);
  }
  text-decoration: none;
  z-index: 1000;
}

// shared styles
.fixed {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
}

.nudge-right {
  margin-right: 24px;
}

// app styles
.about {
  @extend .fixed;
  background: $background-color;
  box-sizing: border-box;
  overflow: auto;
  padding: $spacing * 4 $spacing * 2;
  pointer-events: auto;

  .about-content {
    margin: 0 auto;
    max-width: fit-content;
    text-align: center;
  }
}

// app styles
.charts {
  @extend .fixed;
  background: $background-color;
  box-sizing: border-box;
  overflow: auto;
  padding: $spacing * 4 $spacing * 2;
  pointer-events: auto;

  .charts-view-content {
    margin: 0 auto;
    max-width: fit-content;
    text-align: center;
    bottom: 70px;
    position: relative;
  }
}

.content {
  max-width: 30vw;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  @media only screen and (max-width: $media-phone-max-width) {
    max-width: 60vw;
    right: $spacing * 2;
  }
}

.content-left {
  max-width: 30vw;
  position: absolute;
  left: 5%;
  top: 40%;
  transform: translate(0%, -50%);
  font-size: 32px;
  @media only screen and (max-width: $media-phone-max-width) {
    max-width: 60vw;
    right: $spacing * 2;
  }
}

.content-left-bottom {
  max-width: 10vw;
  position: absolute;
  left: -1%;
  top: 80%;
  transform: translate(0%, -50%);
  font-size: 32px;
  @media only screen and (max-width: $media-phone-max-width) {
    max-width: 60vw;
    left: $spacing * 2;
  }
}

.cover {
  @extend .fixed;
  background-color: $background-color;
}

.details {
  @extend .fixed;
  background: $darken-background-color;
  padding: $spacing;

  .details-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: $spacing * 2 0;
  }
}

.footer {
  @extend .fixed;
  bottom: $spacing;
  left: auto;
  right: $spacing;
  top: auto;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $spacing $spacing * 3;
  @media only screen and (max-width: $media-phone-max-width) {
    padding: $spacing;
  }
}

.hidden {
  opacity: 0;
}

.intro {
  @extend .fixed;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.overlay {
  @extend .fixed;
  .overlay-subtitle {
    @media only screen and (max-width: $media-phone-max-width) {
      display: none;
    }
  }
}

.skip-intro {
  top: 20px;
  position: absolute;
  right: 20px;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin backface-visibility($value) {

  -webkit-backface-visibility: $value;
     -moz-backface-visibility: $value;
      backface-visibility: $value;
}

.starwars {
  
  section {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 1;
  } 

  .start {
    font-size: 200%;
    $start-width: 14em;
    width: $start-width;
    margin: -4em 0 0 (- $start-width / 2);
    text-align: center;
    cursor: pointer;
    
    span {
      color: rgb(75, 213, 238); 
    }
  }    
  
  .sw-intro {
    $intro-width: 15em;
  	width: $intro-width;
    margin: 0 0 0 (- $intro-width / 2);
	  font-size: 200%;
	  font-weight: 400;
  	color: rgb(75, 213, 238);
    opacity: 0;
	  animation: intro 6s ease-out 1s;
  }
  
  .logo {
    opacity: 0;
    animation: logo 9s ease-out 9s; 
    
    svg {
      width: inherit;
    }
  }
  
  .titles {
    $titles-width: 14.65em;
    width: $titles-width;
    margin: 0 0 0 (- $titles-width / 2);
    top: auto;
    bottom: 0;
  	height: 50em;
  	font-size: 350%;
  	text-align: justify;
	  overflow: hidden;
    transform-origin: 50% 100%;
    @include transform(perspective(300px) rotateX(25deg));
  
    > div {
	    position: absolute;
	    top: 100%;
	    animation: titles 81s linear 13s;
    
      > p {
	      margin: 1.35em 0 1.85em 0;
        line-height: 1.35em;
        
        @include backface-visibility(hidden);
      }
    }
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }  
	20% { 
    opacity: 1; 
  }
	90% { 
    opacity: 1; 
  }
	100% { 
    opacity: 0;
  }
}

@keyframes logo {
	0% { 
    $logo-width: 18em;
  	width: $logo-width;
    margin: (- $logo-width / 2) 0 0 (- $logo-width / 2);
    
    @include transform(scale(2.75)); 
    opacity: 1; 
  }
	50% { 
    opacity: 1; 
    
    $logo-width: 18em;
  	width: $logo-width;
    margin: (- $logo-width / 2) 0 0 (- $logo-width / 2);
  }
	100% { 
    @include transform(scale(.1)); 
    opacity: 0;
    
    $logo-width: 18em;
  	width: $logo-width;
    margin: (- $logo-width / 2) 0 0 (- $logo-width / 2);
  }
}

@keyframes titles {
	0% { 
    top: 100%; 
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
	100% { 
    top: 20%; 
    opacity: 0;
  }
}
